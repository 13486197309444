import React from "react"

const Context = React.createContext("fr")

export class LanguageStore extends React.Component {
  defineLanguage = () => {
    if (
      navigator.language.substring(0, 2) !== "en" &&
      navigator.language.substring(0, 2) !== "fr" &&
      navigator.language.substring(0, 2) !== "nl"
    ) {
      return "en"
    } else {
      return navigator.language.substring(0, 2)
    }
  }
  state = { lang: this.defineLanguage() }

  onLanguageChange = lang => {
    this.setState({ lang })
  }

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, onLanguageChange: this.onLanguageChange }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default Context
